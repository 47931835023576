import React, {
  lazy, Suspense, useMemo,
} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import {
  Ellipsis,
  ToastProvider,
  TokenListProvider,
  WalletConnectionProvider,
  Web3Provider,
  WrapperMapProvider,
} from 'common-client';
import { TopBarWrapper } from 'wrappers/TopBarWrapper';
import { ConfigProvider, SUPPORTED_CHAINS } from 'config';
import { SubgraphProvider } from 'contexts/SubgraphContext';
import { Background } from 'components/Background';
import { Box, BoxProps, styled } from '@mui/material';
import { withProfiler } from '@sentry/react';
import { AppTourProvider } from 'contexts/AppTourContext';
import { buttonZeroTourSteps } from 'components/AppTour';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ethereum: any;
  }
}

window.ethereum = window.ethereum || {};

const AppDiv = styled('div', { name: 'AppDiv' })(() => ({
  minHeight: '100vh',
  WebkitTapHighlightColor: 'transparent',
}));

const BodyBox = styled(Box, { name: 'BodyBox' })<BoxProps>(() => ({
  padding: '0 20px 20px 20px',
}));

const ContentBox = styled(Box, { name: 'ContentBox' })<BoxProps>(({ theme }) => ({
  marginTop: '100px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 0,
  },
  '& > *': {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const DynamicLoader = ({ component }: { component: string }) => {
  const LazyComponent = useMemo(() => lazy(() => import(`routes/${component}`)), [component]);
  return (
    <Suspense fallback={<Ellipsis />}>
      <LazyComponent />
    </Suspense>
  );
};

const queryClient = new QueryClient();

function App() {
  return (
    <IntlProvider locale={navigator.language}>
      <Router>
        <Web3Provider>
          <WalletConnectionProvider supportedChains={SUPPORTED_CHAINS}>
            <QueryClientProvider client={queryClient}>
              <TokenListProvider>
                <WrapperMapProvider>
                  <ConfigProvider>
                    <SubgraphProvider>
                      <ToastProvider>
                        <AppTourProvider tourConfigSteps={buttonZeroTourSteps}>
                          <AppDiv>
                            <Background />
                            <TopBarWrapper />
                            <Box display="flex">
                              <BodyBox
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                flex={1}
                              >
                                <ContentBox
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="stretch"
                                >
                                  <Switch>
                                    <Route path="/buy">
                                      <DynamicLoader component="BuyBonds" />
                                    </Route>
                                    <Route path="/sell">
                                      <DynamicLoader component="SellBonds" />
                                    </Route>
                                    <Route path="/holdings">
                                      <DynamicLoader component="BondPositions" />
                                    </Route>
                                    <Route path="/orders">
                                      <DynamicLoader component="BidPositions" />
                                    </Route>
                                    <Route path="/bond/:bondAddress">
                                      <DynamicLoader component="BondDetails" />
                                    </Route>
                                    <Route path="/">
                                      <Redirect to="/buy" />
                                    </Route>
                                  </Switch>
                                </ContentBox>
                              </BodyBox>
                            </Box>
                          </AppDiv>
                        </AppTourProvider>
                      </ToastProvider>
                    </SubgraphProvider>
                  </ConfigProvider>
                </WrapperMapProvider>
              </TokenListProvider>
            </QueryClientProvider>
          </WalletConnectionProvider>
        </Web3Provider>
      </Router>
    </IntlProvider>
  );
}

export default withProfiler(App);
